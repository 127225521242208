<template>
	<div>
		<h1 class="text-center">
			401	Unauthorised
		</h1>
	</div>
</template>

<script>
export default {
	page: {
		title: '401',
		meta: [{ name: 'description', content: '401' }]
	}
}
</script>
